import http from './http-common';

const API_URL = process.env.REACT_APP_API_URL;

// Función de login que guarda el access token en localStorage
const login = async (email, password) => {
  try {
    const response = await fetch(`${API_URL}/users/login/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ email, password }),
      credentials: 'include',
    });

    if (!response.ok) throw new Error('Error en la solicitud de login');

    const data = await response.json();
    if (data.accessToken) {
      // Guardar el access token en localStorage
      localStorage.setItem('accessToken', data.accessToken);
    }
    return data;
  } catch (error) {
    console.error('Error en login:', error);
    throw error;
  }
};

// Función para renovar el access token usando el refresh token
const renewAccessToken = async () => {
  try {
    const response = await fetch(`${API_URL}/users/refresh-token`, {
      method: 'POST',
      credentials: 'include', // Enviar cookies con el refresh token
    });

    if (!response.ok) throw new Error('Error al renovar el access token');

    const data = await response.json();
    if (data.accessToken) {
      // Guardar el nuevo access token
      localStorage.setItem('accessToken', data.accessToken);
      return data.accessToken;
    }
    return null;
  } catch (error) {
    console.error('Error al renovar el access token:', error);
    return null;
  }
};

const handle401Error = async (originalRequestCallback) => {
  try {
    const newAccessToken = await renewAccessToken();
    if (newAccessToken) {
      localStorage.setItem('accessToken', newAccessToken);
      return originalRequestCallback();  // Reintenta la solicitud original
    } else {
      throw new Error('Error al renovar el access token');
    }
  } catch (error) {
    console.error('Error manejando el 401:', error);
    throw error;  // Lanzar el error para manejarlo donde sea necesario
  }
};

// Función para obtener datos del usuario
const getUserData = async () => {
  const token = localStorage.getItem('accessToken'); // Obtener el token del localStorage
  try {
    const response = await fetch(`${API_URL}/users/me`, {
      method: 'GET',
      headers: {
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    });
    if (!response.ok) throw new Error('Error al obtener los datos del usuario');
    const userData = await response.json();
    return userData;
  } catch (error) {
    console.error('Error al obtener los datos del usuario:', error);
    throw error;
  }
};

// Función para registrar un nuevo usuario
const register = async (email, password) => {
  try {
    const response = await http.post(`${API_URL}/users/register`, {
      email,
      password,
    });

    if (response.data.accessToken) {
      // Guardar el access token después del registro
      localStorage.setItem('accessToken', response.data.accessToken);
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};

// Exportar las funciones del servicio de autenticación
const authService = {
  login,
  renewAccessToken,
  getUserData,
  register,
  handle401Error,
};

export default authService;
