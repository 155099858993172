import React, { useState, useEffect, useCallback, useRef } from 'react';
import OrderService from '../services/OrderService';
import OrderDetailsModal from '../components/Orders/OrderDetailsModal';
import Header from '../components/Header/Header';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faUser, faFilter } from '@fortawesome/free-solid-svg-icons';
import "./MyOrdersPage.css";

const MyOrdersPage = ({ store }) => {
    const [orders, setOrders] = useState([]);
    const [selectedOrder, setSelectedOrder] = useState(null);
    const [currentPage, setCurrentPage] = useState(1);
    const [loadedPage, setLoadedPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
    const [sortOption, setSortOption] = useState('desc');
    const [filterState, setFilterState] = useState('');
    const [showFilterMenu, setShowFilterMenu] = useState(false);

    const loadingRef = useRef(false);  // Ref para manejar loading sin causar bucles

    // Función de fetch optimizada
    const fetchOrders = useCallback(async (page = 1) => {
        if (loadingRef.current) return;
        loadingRef.current = true;  // Evitar múltiples llamadas

        try {
            const response = await OrderService.getSellerOrders(store, page, sortOption, filterState);
            const { data, totalPages } = response;

            setOrders(prevOrders => page === 1 ? data : [...prevOrders, ...data]);
            setHasMore(page < totalPages);
            setLoadedPage(page);
        } catch (error) {
            console.error('Error al obtener las órdenes:', error);
        } finally {
            loadingRef.current = false;
        }
    }, [store, sortOption, filterState]);

    // Hacer fetch cuando cambien sortOption o filterState, o cuando se carga la página por primera vez
    useEffect(() => {
        setOrders([]);  // Limpiar los pedidos cuando se cambia el filtro
        setCurrentPage(1);
        fetchOrders(1);
    }, [sortOption, filterState, fetchOrders]);

    // Manejar el scroll con throttle para evitar llamadas duplicadas
    const handleScroll = useCallback(() => {
        if (
            window.innerHeight + document.documentElement.scrollTop >=
            document.documentElement.offsetHeight - 500 && hasMore && !loadingRef.current
        ) {
            setCurrentPage(prevPage => prevPage + 1);
        }
    }, [hasMore]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);

    // Hacer fetch cuando cambie la página
    useEffect(() => {
        if (currentPage > loadedPage) {
            fetchOrders(currentPage);
        }
    }, [currentPage, loadedPage, fetchOrders]);

    const handleSortChange = (e) => {
        setSortOption(e.target.value);
    };

    const handleFilterChange = (e) => {
        setFilterState(e.target.value);
    };

    const handleOrderClick = (orderId) => {
        const order = orders.find(o => o._id === orderId);
        setSelectedOrder(order);
    };

    const toggleFilterMenu = () => {
        setShowFilterMenu(!showFilterMenu);
    };

    const handleUpdateStatus = async (orderId, newStatus) => {
        try {
            const data = await OrderService.updateOrderStatus(orderId, newStatus);
            if (data.success) {
                setOrders(orders.map(o => (o._id === orderId ? { ...o, status: newStatus } : o)));
            }
        } catch (error) {
            console.error('Error al actualizar el estado del pedido:', error);
        }
    };

    const handleCloseModal = () => {
        setSelectedOrder(null);
    };

    return (
        <>
            <Header />
            <div className="orders-page">
                <div className="orders-header-container">
                    <h1 className="orders-header">Pedidos</h1>
                    <button className="filter-btn" onClick={toggleFilterMenu}>
                        <span>Filtros </span>
                        <FontAwesomeIcon icon={faFilter} />
                    </button>
                </div>

                {showFilterMenu && (
                    <div className='filter-menu'>
                        <label>
                            Ordenar por:
                            <select value={sortOption} onChange={handleSortChange}>
                                <option value="asc">Más antiguo</option>
                                <option value="desc">Más reciente</option>
                            </select>
                        </label>
                        <label>
                            Filtrar por estado:
                            <select value={filterState} onChange={handleFilterChange}>
                                <option value="">Todos</option>
                                <option value="pending">Pendiente</option>
                                <option value="shipped">Enviado</option>
                                <option value="delivered">Entregado</option>
                                <option value="cancelled">Cancelado</option>
                            </select>
                        </label>
                    </div>
                )}

                <div className="orders-container">
                    {(orders.length === 0 && !hasMore) && <h2 className="no-orders-message">No se realizó ningún pedido a esta tienda</h2>}
                    {orders.map((order) => (
                        <div key={order._id} className="order-item">
                            <div className='order-details'>
                                <div className="order-header">
                                    <span className="order-number">#{order.orderNumber}</span>
                                    <span className="order-date">{new Date(order.createdAt).toLocaleDateString()}</span>
                                    <button className="view-details-btn" onClick={() => handleOrderClick(order._id)}>
                                        <FontAwesomeIcon icon={faEye} />
                                        <span>Ver Detalles</span>
                                    </button>
                                </div>
                                <div className="order-sub-header">
                                    <FontAwesomeIcon icon={faUser} />
                                    <span className="order-customer">
                                        {`${order.user.firstName} ${order.user.lastName}`}
                                    </span>
                                </div>
                                <div className="order-products">
                                    {order.products.map((product, index) => (
                                        <div key={index} className="product-item">
                                            <div className="product-thumbnail">
                                                {product.productId?.imageUrls?.thumbnail && (
                                                    <img
                                                        src={product.productId.imageUrls.thumbnail}
                                                        alt={product.productId.name}
                                                    />
                                                )}
                                            </div>
                                            <div className="product-info">
                                                <span id="order-product-name">{product.productId?.name || 'Producto'}</span>
                                                <span id="order-product-units">{product.quantity} unidad/es</span>
                                                <span id="order-product-price">${product.productId?.price || 'N/A'}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>

                                <div className="order-summary">
                                    <span>Total: ${order.subTotal}</span>
                                    <div className="order-status-dropdown">
                                        <select
                                            value={order.status}
                                            onChange={(e) => handleUpdateStatus(order._id, e.target.value)}
                                        >
                                            <option value="pending">Pendiente</option>
                                            <option value="shipped">Enviado</option>
                                            <option value="delivered">Entregado</option>
                                            <option value="cancelled">Cancelado</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="order-customer-info" id='order-list-customer-info'>
                                <h3>Información del Cliente</h3>
                                <p><strong>Nombre:</strong> {order.user.firstName} {order.user.lastName}</p>
                                <p><strong>Dirección:</strong> {order.user.address}, {order.user.city}, {order.user.state}, {order.user.country}, {order.user.postalCode}</p>
                                <p><strong>Teléfono:</strong> {order.user.phoneNumber}</p>
                            </div>
                        </div>
                    ))}
                </div>

                {selectedOrder && (
                    <OrderDetailsModal
                        order={selectedOrder}
                        onClose={handleCloseModal}
                        onUpdateStatus={handleUpdateStatus}
                    />
                )}
            </div>
        </>
    );
};

export default MyOrdersPage;
