import authService from './AuthServices';
// UserService.js
export const fetchUserData = async (token) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/users`, {
            method: 'GET',
            headers: {
                'Authorization': `Bearer ${token}`,
            },
        });

        if (response.status === 401) {
            return authService.handle401Error(() => fetchUserData(token));
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error al obtener datos del usuario:', error);
        throw error;
    }
};

export const updateUserProfile = async (token, userData) => {
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/users/update`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(userData),
        });

        if (response.status === 401) {
            return authService.handle401Error(() => updateUserProfile(token, userData));
        }

        if (response.ok) {
            const updatedUser = await response.json();
            return updatedUser;
        } else {
            throw new Error('Error al actualizar el perfil');
        }
    } catch (error) {
        console.error('Error al actualizar el perfil:', error);
        throw error;
    }
};
