import React, { useState } from 'react';
import './EditProductModal.css';
import { updateProduct } from '../../services/ProductServices';

const EditProductModal = ({ store, producto, onClose, onProductEdited }) => {
    const [name, setName] = useState(producto.name);
    const [description, setDescription] = useState(producto.description);
    const [price, setPrice] = useState(producto.price);
    const [stock, setStock] = useState(producto.stock);
    const [isFeatured, setIsFeatured] = useState(producto.isFeatured);
    const [isHidden, setIsHidden] = useState(producto.isHidden);

    const handleSubmit = async (e) => {
        e.preventDefault();

        const updateData = {
            name,
            description,
            price,
            stock,
            isFeatured,
            isHidden,
        };

        try {
            await updateProduct(store,producto.uuid, updateData);  // Llamada a la función externa
            onProductEdited();  // Llama a la función para actualizar la galería
            onClose();
        } catch (error) {
            console.error('Error al editar el producto:', error);
        }
    };

    return (
        <div className="edit-product-modal-backdrop" onClick={onClose}>
            <div className="edit-product-modal-content" onClick={(e) => e.stopPropagation()}>
                <h2>Editar Producto</h2>
                <form onSubmit={handleSubmit} className="edit-product-form">
                    <label>
                        Nombre:
                        <input type="text" value={name} onChange={(e) => setName(e.target.value)} required />
                    </label>
                    <label>
                        Descripción:
                        <textarea value={description} onChange={(e) => setDescription(e.target.value)} required />
                    </label>
                    <table>
                        <tbody>
                            <tr>
                                <td><label>Precio:</label></td>
                                <td><input type="number" step="0.01" value={price} onChange={(e) => setPrice(e.target.value)} required /></td>
                                <td><label>Cantidad:</label></td>
                                <td><input type="number" value={stock} onChange={(e) => setStock(e.target.value)} required /></td>
                            </tr>
                            <tr>
                                <td colSpan="4">
                                    <div className="checkbox-container">
                                        <div className="checkbox-wrapper">
                                            <input
                                                type="checkbox"
                                                checked={isFeatured}
                                                onChange={(e) => setIsFeatured(e.target.checked)}
                                                className="checkbox-input"
                                            />
                                            <span>Producto destacado</span>
                                        </div>
                                        <div className="checkbox-wrapper">
                                            <input
                                                type="checkbox"
                                                checked={isHidden}
                                                onChange={(e) => setIsHidden(e.target.checked)}
                                                className="checkbox-input"
                                            />
                                            <span>Ocultar producto</span>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button type="submit" className="submit-button">Guardar</button>
                </form>
                <button onClick={onClose} className="close-button-modal">Cerrar</button>
            </div>
        </div>
    );
};

export default EditProductModal;
