import authService from './AuthServices'; // Para manejar la autenticación

const API_URL = process.env.REACT_APP_API_URL;

const getStores = async () => {
  try {
    let token = localStorage.getItem('accessToken'); // Obtenemos el token
    let response = await fetch(`${API_URL}/stores/`, {
      method: 'GET',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,
      },
    });

    if (response.status === 401) {
      // Si el token ha expirado, intentamos renovarlo
      const newAccessToken = await authService.renewAccessToken();
      if (newAccessToken) {
        token = newAccessToken;
        response = await fetch(`${API_URL}/stores/`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${token}`,
          },
        });
      } else {
        throw new Error('No se pudo renovar el access token');
      }
    }

    if (!response.ok) {
      throw new Error('Error al obtener las tiendas');
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error('Error en getStores:', error);
    throw error;
  }
};

// Asigna el objeto a una variable antes de exportarlo
const storeServices = { getStores };

export default storeServices;
