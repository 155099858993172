import React, { useContext } from 'react';
import { CartContext } from './CartContext';
import './CartModal.css';
import { useNavigate, useParams } from 'react-router-dom';

const CartModal = ({ cartEntranceState, closeCart }) => {
  const { cart, removeFromCart, addToCart } = useContext(CartContext);
  const navigate = useNavigate();
  const { store } = useParams();

  const total = cart.reduce((acc, product) => acc + product.price * product.amount, 0).toFixed(2);
  
  let modalClass;
  if (cartEntranceState === "cartActionEntering") {
    modalClass = "crt-modal cartActionEntering";
  } else if (cartEntranceState === "cartActionExit") {
    modalClass = "crt-modal cartActionExit";
  } else {
    modalClass = "crt-modal";
  }

  const handlePurchase = () => {
    navigate(`/${store}/checkout`);
  };

  return (
    <div className="crt-modal-backdrop" onClick={closeCart}>
      <div className={modalClass} onClick={e => e.stopPropagation()}>
        <div className="crt-content">
          {cart.map((product, index) => (
            <div key={index} className="crt-pdct">
              <div className="thumbnail-circle">
                <img src={product.imageUrls.thumbnail} alt={product.name} className="thumbnail-image" />
              </div>
              <span className='crt-pdct-name'>{product.name}</span>
              <span className='crt-pdct-price'>${product.price}</span>
              <div className='amount-handler'>
                <button className='remove-cart-item-btn' onClick={() => removeFromCart(product._id)}>-</button>
                <span className='crt-pdct-amount'>{product.amount}</span>
                <button 
                  className='add-cart-item-btn' 
                  onClick={() => addToCart(product)} 
                  disabled={product.amount >= product.stock} // Deshabilitar si se alcanza el stock
                >+</button>
              </div>
            </div>
          ))}
          <div className="cart-total">
            <span>Total: ${total}</span>
          </div>
          <button className='purchase-btn' onClick={handlePurchase}>Comprar</button>
        </div>
        <button className='close-button' onClick={closeCart}>X</button>
      </div>
    </div>
  );
}

export default CartModal;
