import React from 'react';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPencilAlt, faArrowsAlt } from '@fortawesome/free-solid-svg-icons';

const DraggableProductItem = ({ product, isOwner, openEditProductModal, openModal }) => {
    const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({
        id: product.uuid,
        disabled: product.isHidden || product.stock <= 0 // Deshabilitamos el arrastre si el product está oculto
    });

    const style = {
        transform: CSS.Transform.toString(transform),
        transition: transition,
        opacity: isDragging ? 0 : 1,
        cursor: isDragging ? 'grabbing' : 'grab',
        zIndex: isDragging ? 10 : 1,
    };

    return (
        <div
            ref={setNodeRef}
            style={style}
            className={`image-item ${(product.isHidden || product.stock <= 0) ? 'hidden-product' : ''}`}
            {...attributes} // Solo atributos generales, no listeners en el contenedor principal
        >
            {product.isHidden && <div className="hidden-label">Oculto</div>}
            {product.stock <= 0 && !product.isHidden && <div className="out-of-stock-label">Sin Stock</div>}
            <div className="image-container">
                <img src={product.imageUrls.thumbnail} alt={product.name} onClick={() => openModal(product)} />
                <div className="price-tag">
                    <span className="price-text">${product.price}</span>
                </div>
            </div>
            <p><b>{product.name}</b></p>
            {isOwner && (
                <>
                    <p className='product-stock'>Stock: {product.stock}</p>
                    {!product.isHidden && product.stock > 0 && 
                        <div className="move-icon-container" {...listeners}> {/* Aplicamos los listeners solo al botón */}
                            <FontAwesomeIcon icon={faArrowsAlt} className="move-icon" />
                        </div>}
                    <div className="edit-icon-container" onClick={() => openEditProductModal(product)}>
                        <FontAwesomeIcon icon={faPencilAlt} className="edit-icon" />
                    </div>
                </>
            )}
        </div>
    );
};

export default DraggableProductItem;
