import React, { useContext, useState, useEffect } from 'react';
import { CartContext } from '../components/Cart/CartContext'; // El contexto del carrito
import { useLocation, useNavigate } from 'react-router-dom';
import Header from '../components/Header/Header';  // Agregar el Header
import './CheckoutPage.css';
import { fetchUserData, updateUserProfile } from '../services/UserService'; // Importamos los servicios

const CheckoutPage = () => {
    const { cart, createOrder, clearCart } = useContext(CartContext);
    const [isLoggedIn, setIsLoggedIn] = useState(false); // Estado para verificar si el usuario está logueado
    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
        address: '',
        city: '',
        state: '',
        country: '',
        postalCode: '',
        phoneNumber: '',
    });
    const location = useLocation();
    const navigate = useNavigate(); // Para redirigir a la página de login
    const [isEditing, setIsEditing] = useState(false); // Para controlar el modo edición
    const total = cart.reduce((acc, product) => acc + product.price * product.amount, 0).toFixed(2);

    useEffect(() => {
        const token = localStorage.getItem('accessToken');
        if (token) {
            fetchUserData(token).then(data => {
                setUserData({
                    firstName: data.firstName,
                    lastName: data.lastName,
                    address: data.address,
                    city: data.city,
                    state: data.state,
                    country: data.country,
                    postalCode: data.postalCode,
                    phoneNumber: data.phoneNumber,
                });
            }).catch(error => {
                console.error('Error al obtener datos del usuario:', error);
            });
            setIsLoggedIn(true);
        } else {
            setIsLoggedIn(false);
        }
    }, []);

    const handleSubmitOrder = async () => {
        const actualRoute = location.pathname.split('/')[1];
        try {
            const orderResponse = await createOrder(actualRoute, userData);
            clearCart();
            navigate(`/${actualRoute}/thankyou`, { state: { order: orderResponse } });
        } catch (error) {
            console.error('Error al crear el pedido:', error);
        }
    }

    const handleLoginClick = () => {
        navigate('/login');
    };

    return (
        <div className="checkout-page">
            <Header />
            <div className="checkout-container">
                <div className="checkout-left">
                    <h2>Datos de Envío</h2>
                    {isLoggedIn ? (
                        <div>
                            {isEditing ? (
                                <form>
                                    <label>
                                        Nombre:
                                        <input
                                            type="text"
                                            value={userData.firstName}
                                            onChange={(e) => setUserData({ ...userData, firstName: e.target.value })}
                                        />
                                    </label>
                                    <label>
                                        Apellido:
                                        <input
                                            type="text"
                                            value={userData.lastName}
                                            onChange={(e) => setUserData({ ...userData, lastName: e.target.value })}
                                        />
                                    </label>
                                    <label>
                                        Dirección:
                                        <input
                                            type="text"
                                            value={userData.address}
                                            onChange={(e) => setUserData({ ...userData, address: e.target.value })}
                                        />
                                    </label>
                                    <label>
                                        Ciudad:
                                        <input
                                            type="text"
                                            value={userData.city}
                                            onChange={(e) => setUserData({ ...userData, city: e.target.value })}
                                        />
                                    </label>
                                    <label>
                                        Estado:
                                        <input
                                            type="text"
                                            value={userData.state}
                                            onChange={(e) => setUserData({ ...userData, state: e.target.value })}
                                        />
                                    </label>
                                    <label>
                                        País:
                                        <input
                                            type="text"
                                            value={userData.country}
                                            onChange={(e) => setUserData({ ...userData, country: e.target.value })}
                                        />
                                    </label>
                                    <label>
                                        Código Postal:
                                        <input
                                            type="text"
                                            value={userData.postalCode}
                                            onChange={(e) => setUserData({ ...userData, postalCode: e.target.value })}
                                        />
                                    </label>
                                    <label>
                                        Teléfono:
                                        <input
                                            type="text"
                                            value={userData.phoneNumber}
                                            onChange={(e) => setUserData({ ...userData, phoneNumber: e.target.value })}
                                        />
                                    </label>
                                    <button className='address-button' type="button" onClick={updateUserProfile}>
                                        Guardar
                                    </button>
                                </form>
                            ) : (
                                <div>
                                    <p>
                                        <strong>Nombre:</strong> {userData.firstName}
                                    </p>
                                    <p>
                                        <strong>Apellido:</strong> {userData.lastName}
                                    </p>
                                    <p>
                                        <strong>Dirección:</strong> {userData.address}
                                    </p>
                                    <p>
                                        <strong>Ciudad:</strong> {userData.city}
                                    </p>
                                    <p>
                                        <strong>Estado:</strong> {userData.state}
                                    </p>
                                    <p>
                                        <strong>País:</strong> {userData.country}
                                    </p>
                                    <p>
                                        <strong>Código Postal:</strong> {userData.postalCode}
                                    </p>
                                    <p>
                                        <strong>Teléfono:</strong> {userData.phoneNumber}
                                    </p>
                                    <button className='address-button' onClick={() => setIsEditing(true)}>Editar</button>
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            <button className="checkout-login-btn" onClick={handleLoginClick}>
                                Iniciar Sesión
                            </button>
                            <h3>Continuar como invitado</h3>
                            <form>
                                <label>
                                    Nombre:
                                    <input
                                        type="text"
                                        value={userData.firstName}
                                        onChange={(e) => setUserData({ ...userData, firstName: e.target.value })}
                                        required
                                    />
                                </label>
                                <label>
                                    Apellido:
                                    <input
                                        type="text"
                                        value={userData.lastName}
                                        onChange={(e) => setUserData({ ...userData, lastName: e.target.value })}
                                        required
                                    />
                                </label>
                                <label>
                                    Dirección:
                                    <input
                                        type="text"
                                        value={userData.address}
                                        onChange={(e) => setUserData({ ...userData, address: e.target.value })}
                                        required
                                    />
                                </label>
                                <label>
                                    Ciudad:
                                    <input
                                        type="text"
                                        value={userData.city}
                                        onChange={(e) => setUserData({ ...userData, city: e.target.value })}
                                        required
                                    />
                                </label>
                                <label>
                                    Estado:
                                    <input
                                        type="text"
                                        value={userData.state}
                                        onChange={(e) => setUserData({ ...userData, state: e.target.value })}
                                        required
                                    />
                                </label>
                                <label>
                                    País:
                                    <input
                                        type="text"
                                        value={userData.country}
                                        onChange={(e) => setUserData({ ...userData, country: e.target.value })}
                                        required
                                    />
                                </label>
                                <label>
                                    Código Postal:
                                    <input
                                        type="text"
                                        value={userData.postalCode}
                                        onChange={(e) => setUserData({ ...userData, postalCode: e.target.value })}
                                        required
                                    />
                                </label>
                                <label>
                                    Teléfono:
                                    <input
                                        type="text"
                                        value={userData.phoneNumber}
                                        onChange={(e) => setUserData({ ...userData, phoneNumber: e.target.value })}
                                        required
                                    />
                                </label>
                            </form>
                        </div>
                    )}
                </div>

                <div className="checkout-right">
                    <h2>Tu Carrito</h2>
                    {cart.map((product, index) => (
                        <div key={index} className="cart-item">
                            <div className="thumbnail-circle">
                                <img src={product.imageUrls.thumbnail} alt={product.name} className="thumbnail-image" />
                            </div>
                            <span>{product.name}</span>
                            <span>Cantidad: {product.amount}</span>
                            <span>Precio: ${product.price}</span>
                        </div>
                    ))}
                    <div className="cart-total">
                        <strong>Total: ${total}</strong>
                    </div>
                    <button className="confirm-order-btn" onClick={handleSubmitOrder}>
                        Confirmar Pedido
                    </button>
                </div>
            </div>
        </div>
    );
};

export default CheckoutPage;
