import authService from './AuthServices';  // Importamos el servicio de autenticación
import axios from 'axios';

export const fetchProducts = async (store) => {
    const token = localStorage.getItem('accessToken');
    const url = `${process.env.REACT_APP_API_URL}/stores/products/${store}`;

    try {
        const response = await fetch(url, {
            method: 'GET',
            headers: token ? { 'Authorization': `Bearer ${token}` } : {}
        });

        if (response.status === 401) {
            return authService.handle401Error(() => fetchProducts(store));
        }

        if (!response.ok) {
            throw new Error('Error al obtener los productos');
        }

        const data = await response.json();
        return data; // Esto devuelve los productos al componente
    } catch (error) {
        console.error('Error al cargar productos:', error);
        throw error;  // Re-lanzamos el error para manejarlo desde el componente
    }
};

export const createProduct = async (store, productData, image) => {
    const formData = new FormData();
    formData.append('name', productData.name);
    formData.append('description', productData.description);
    formData.append('price', productData.price);
    formData.append('stock', productData.stock);
    formData.append('isFeatured', productData.isFeatured);
    formData.append('isHidden', productData.isHidden);
    if (image) {
      formData.append('file', image);
    }
  
    const token = localStorage.getItem('accessToken');
  
    try {
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/products/create/${store}`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${token}`,
        },
      });

      if (response.status === 401) {
          return authService.handle401Error(() => createProduct(store, productData, image));
      }

      return response.data;  // Devolvemos los datos del producto creado
    } catch (error) {
      console.error('Error al agregar el producto:', error);
      throw error;
    }
  }; 

  export const updateProduct = async (store, uuid, productData) => {
    const token = localStorage.getItem('accessToken');
  
    try {
      const response = await axios.put(`${process.env.REACT_APP_API_URL}/products/${store}/update/${uuid}`, productData, {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({ productData }),
      });

      if (response.status === 401) {
          return authService.handle401Error(() => updateProduct(store, uuid, productData));
      }
      return response.data;  // Devuelve los datos del producto actualizado
    } catch (error) {
      console.error('Error al editar el producto:', error);
      throw error;  // Lanza el error para manejarlo en el componente
    }
  };
  
export const updateProductPositionOnServer = async (store, updatedProducts) => {
    const token = localStorage.getItem('accessToken');
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/products/${store}/updatePositions`, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify({ products: updatedProducts }),
        });

        if (response.status === 401) {
            return authService.handle401Error(() => updateProductPositionOnServer(store, updatedProducts));
        }

        if (!response.ok) {
            throw new Error('Error al actualizar las posiciones de los productos');
        }

        return await response.json();
    } catch (error) {
        console.error('Error al actualizar las posiciones:', error);
        throw error;
    }
};

export const handleDeleteProduct = async (store, uuid) => {
    const token = localStorage.getItem('accessToken');
    try {
        const response = await fetch(`${process.env.REACT_APP_API_URL}/products/${store}/delete/${uuid}`, {
            method: 'DELETE',
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        if (response.status === 401) {
            return authService.handle401Error(() => handleDeleteProduct(store, uuid));
        }

        if (!response.ok) {
            throw new Error('Error al eliminar el producto');
        }

        return true; // Para indicar que se eliminó correctamente
    } catch (error) {
        console.error('Error al eliminar el producto:', error);
        throw error;
    }
};

const ProductServices = {
    fetchProducts,
    updateProductPositionOnServer,
    handleDeleteProduct,
    updateProduct,
};

export default ProductServices;