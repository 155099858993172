import React, { useState } from "react";
import authService from '../../services/AuthServices';
import './LoginPage.css';
import { useNavigate } from 'react-router-dom';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [isLoginAttempt, setIsLoginAttempt] = useState(true);
    const [showRegisterOption, setShowRegisterOption] = useState(false);
    const navigate = useNavigate();
    
    const handleEmailChange = (e) => {
        setEmail(e.target.value);
        setErrorMsg('');
    };
    
    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
        setErrorMsg('');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isLoginAttempt) {
            try {
                const { accessToken } = await authService.login(email, password);  // Obtener el accessToken del login
                localStorage.setItem('accessToken', accessToken);  // Guardar el accessToken en localStorage
                navigate('/');
            } catch (error) {
                setShowRegisterOption(true);
                setErrorMsg('Parece que este mail no está registrado, ¿quieres crear una cuenta?');
            }
        } else {
            try {
                if (email !== confirmEmail || password !== confirmPassword) {
                    setErrorMsg('El email o la contraseña no coinciden con su confirmación.');
                    return;
                }
                await authService.register(email, password);
                navigate('/');
            } catch (error) {
                setErrorMsg('Hubo un problema al crear tu cuenta. Por favor, intenta de nuevo.');
            }
        }
    };

    const canSubmit = email.length >= 6 && password.length >= 6 && (isLoginAttempt || (email === confirmEmail && password === confirmPassword));
    const handleRegisterOptionClick = () => {
        setShowRegisterOption(false);
        setIsLoginAttempt(false);
    };

    return (
        <div className="forms-container" onClick={(e) => e.stopPropagation()}>
            <form className="login-form" onSubmit={handleSubmit}>
                <h2 className="title">Iniciar sesión</h2>
                {showRegisterOption && (
                    <>
                        <p>{errorMsg}</p>
                        <button type="button" onClick={handleRegisterOptionClick}>Sí</button>
                    </>
                )}
                <input
                    className="email-input"
                    type="email"
                    value={email}
                    onChange={handleEmailChange}
                    placeholder="Email"
                    required
                    minLength="6"
                />
                <input
                    className="email-input"
                    type="email"
                    value={confirmEmail}
                    onChange={(e) => setConfirmEmail(e.target.value)}
                    placeholder="Confirmar Email"
                    style={{ display: isLoginAttempt ? 'none' : 'block' }}
                />
                <input
                    className="password-input"
                    type="password"
                    value={password}
                    onChange={handlePasswordChange}
                    placeholder="Contraseña"
                    required
                    minLength="6"
                />
                <input
                    type="password"
                    className="password-input"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirmar Contraseña"
                    style={{ display: isLoginAttempt ? 'none' : 'block' }}
                />
                <button 
                    type="submit" 
                    disabled={!canSubmit}
                >
                  {isLoginAttempt ? 'Iniciar Sesión' : 'Crear Cuenta'}
                </button>
                <p className="forgot-password-link">Olvidé mi contraseña</p>
            </form>
        </div>
    );
};

export default LoginPage;
