import React, { useState, useContext, useEffect, useRef } from 'react';
import './Header.css';
import { CartContext } from '../Cart/CartContext';
import CartModal from '../Cart/CartModal';
import logo from '../../assets/prodexpo-logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';
import { faBasketShopping } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import storeServices from '../../services/StoreServices'; // Asegúrate de importar los servicios

const Header = () => {
  const navigate = useNavigate();
  const { cart } = useContext(CartContext);
  const productAmount = cart.reduce((acc, product) => acc + product.amount, 0);

  const [cartState, setCartState] = useState(null);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [userStores, setUserStores] = useState([]);
  const [showStoreMenu, setShowStoreMenu] = useState(false);
  const [menuAnimation, setMenuAnimation] = useState(false); // Estado para la animación

  const menuRef = useRef(null);

  useEffect(() => {
    const token = localStorage.getItem('accessToken');
    setIsLoggedIn(!!token);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowButtons(false);
        setShowStoreMenu(false); // También cerramos el menú de tiendas
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [menuRef]);

  const handleMyOrdersClick = async () => {
    setShowStoreMenu(!showStoreMenu); // Cambiamos el estado del menú
    if (!showStoreMenu) {
      try {
        const stores = await storeServices.getStores(); // Llamamos al servicio de tiendas
        setUserStores(stores);
      } catch (error) {
        console.error('Error fetching stores', error);
      }
    }
  };

  const toggleButtons = () => {
    if (!showButtons) {
      setMenuAnimation(true); // Activar la animación cuando se abre el menú
    }
    setShowButtons(!showButtons);
  };

  useEffect(() => {
    if (menuAnimation) {
      // Restablecer el estado de animación después de que haya comenzado
      const timer = setTimeout(() => {
        setMenuAnimation(false);
      }, 500); // Duración de la animación (0.5s)
      return () => clearTimeout(timer);
    }
  }, [menuAnimation]);

  return (
    <div className='header'>
      <div className='menu-button' id='menu-button' ref={menuRef} onClick={toggleButtons}>
        <FontAwesomeIcon className='header-icon' icon={faUser} style={{ color: "#74C0FC" }} />
        <div className={`menu-wrapper ${menuAnimation && showButtons ? 'swing-in-top-fwd' : ''}`}>
          {showButtons && (
            <div className='menu-content'>
              {!isLoggedIn ? (
                <button className='login-btn' id="btn1" onClick={() => navigate('/login')}>Iniciar sesión</button>
              ) : (
                <>
                  <button className='logout-btn' id='btn1' onClick={() => {
                    localStorage.removeItem('accessToken');
                    setIsLoggedIn(false);
                    window.location.reload();
                  }}>Cerrar sesión</button>
                  <button className='my-orders-btn' id='btn2' onMouseEnter={handleMyOrdersClick}>Mis Pedidos</button>
                  {showStoreMenu && (
                    <div className='store-menu'>
                      {userStores.map((store) => (
                        <button key={store._id} className='store-btn' onClick={() => navigate(`/${store.route}/orders`)}>
                          {store.name}
                        </button>
                      ))}
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      <img src={logo} alt="Logo" className='logo' onClick={() => navigate('/')} />
      <button onClick={() => setCartState('cartActionEntering')} className='cart-btn'>
        <div className='cart-count'>{productAmount}</div>
        <FontAwesomeIcon className='cart-icon' icon={faBasketShopping} style={{ color: "#74C0FC" }} />
      </button>
      {cartState && <CartModal cartEntranceState={cartState} closeCart={() => setCartState(null)} />}
    </div>
  );
};

export default Header;
