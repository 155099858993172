import React, { useState, useEffect } from 'react';
import Header from '../components/Header/Header';
import LoginPage from '../components/Login/LoginPage';
import StoreServices from '../services/StoreServices';
import './HomePage.css';
import { Link } from 'react-router-dom';
import CreateStoreModal from '../components/Store/CreateStoreModal';
import shoppingw from '../assets/shopping_w.png';
import stepsImg from '../assets/steps.png';

const HomePage = () => {
  const [stores, setStores] = useState([]);
  const [error, setError] = useState(null);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isCreateStoreModalOpen, setIsCreateStoreModalOpen] = useState(false);

  const toggleLoginModal = () => {
    setIsLoginModalOpen(!isLoginModalOpen);
  };

  const toggleCreateStoreModal = () => {
    setIsCreateStoreModalOpen(!isCreateStoreModalOpen);
  };

  const fetchStores = async () => {
    try {
      const data = await StoreServices.getStores();
      setStores(data);
    } catch (error) {
      setError('Error al obtener las tiendas');
    }
  };

  useEffect(() => {
    fetchStores();
  }, []);

  const handleStoreCreated = async () => {
    await fetchStores(); // Vuelve a obtener las tiendas
  };

  return (
    <div className="home-container">
      <Header toggleLoginModal={toggleLoginModal} />
      <header/>
      <div className='home-pres'>
        <img className='home-img' id='home-pres-img' src={shoppingw} alt='woman wlaking with shopping bags'/>
        <div id='home-pres-text'>
          <h1 className='main-text'>Prodexpo: Compra y vende fácil</h1>
          <p className='main-subtext'>Explora tiendas y productos como si estuvieras en un centro comercial.</p>
          <button onClick={toggleCreateStoreModal} className="create-store-button" id='create-store-button-home'>Comienza Ahora</button>
        </div>
      </div>
      <div className='home-pres' id='home-info-cards'>
        <div id='info-cards-text'>
          <h1 className='info-cards-title'>Desbloquea el potencial de tu negocio</h1>
          <p className='info-cards-subtitle'>Transforma tus ideas en una tienda online sobresaliente sin esfuerzo</p>
        </div>
        <div className='info-cards-container'>
          <div className='info-card'>
            <p className='info-card-title'>Diseños de alta conversion</p>
            <p className='info-card-description'>Comprobado y en constante investigacion para maximizar tus ventas.</p>
          </div>
          <div className='info-card'>
            <p className='info-card-title'>Interfaz amigable</p>
            <p className='info-card-description'>Queremos que el proceso sea simple y sin pasos innecesarios. Cualquier persona puede manejar nuestra app.</p>
          </div>
          <div className='info-card'>
            <p className='info-card-title'>Orientacion al cliente</p>
            <p className='info-card-description'>Siempre tiene la razon y nosotros lo sabemos. Un cliente satisfecho siempre vuelve!.</p>
          </div>
          <div className='info-card'>
            <p className='info-card-title'>Sin necesidad de registro</p>
            <p className='info-card-description'>Tus clientes pueden ver y comprar productos sin necesidad de crear un perfil si no lo desea.</p>
          </div>
        </div>
      </div>

      <div className='home-pres' id='home-steps'>
        <div className='steps-text' id='info-cards-text'>
          <h1 className='info-cards-title' id='steps-title'>¿Cómo funciona?</h1>
          <p className='info-cards-subtitle' id='steps-subtitle'>Sigue estos sencillos pasos para abrir tu tienda online.</p>
          <img className='steps-img' id='steps-img' src={stepsImg} alt='cozy store photo'/>
        </div>
        <div className='info-cards-container' id='steps-container'>
          <div className='info-card' id='card-wrapper'>
            <p className='info-card-title'>Paso 1: Registrate</p>
            <p className='info-card-description'>Es totalmente gratuito y facil, ¡toma menos de un minuto!
                                                <br/> Nada de procesos complicados ni tiempos de espera.
            </p>
          </div>
          <div className='info-card' id='card-wrapper'>
            <p className='info-card-title'>Paso 2: Personaliza</p>
            <p className='info-card-description'>Ingresa el nombre de tu tienda, logotipo y rubro.
                                                <br/>esta sera la presentacion de tu propio local vitual.
            </p>
          </div>
          <div className='info-card' id='card-wrapper'>
            <p className='info-card-title'>Paso 3: Stockeate</p>
            <p className='info-card-description'>Agrega los productos o servicios que ofreces con sus imagenes,
                                                 descripciones, precio y stock. ¡Ya casi terminas! ¿sabes lo que sigue? 

            </p>
          </div>
          <div className='info-card' id='card-wrapper'>
            <p className='info-card-title'>Paso 4: vende!</p>
            <p className='info-card-description'>Copia el enlace o el codigo QR de tu tienda y ofrecelo a tus clientes, ellos podran ver y encargar tus ordenes de forma rapida y sencilla sin necesidad de registrarse. 
                                                <br/>¡ya tienes tu propia tienda!
            </p>
          </div>
        </div>
      </div>
    <div className='home-login'>
      <LoginPage/>
    </div>
      {error && <p>{error}</p>}
      <div>
        {stores.map((store) => (
        <div key={store.id}>
          <Link to={`/${store.route}`}>
            {store.name}
          </Link>
        </div>
        ))}
          <div>
            <button onClick={toggleCreateStoreModal} className="create-store-button" >Crear nueva tienda</button>
          </div>
      </div>
      {isCreateStoreModalOpen && <CreateStoreModal onClose={toggleCreateStoreModal} onStoreCreated={handleStoreCreated} />}
    </div>
  );
};

export default HomePage;
