import React, { useState, useEffect, useCallback } from 'react';
import DraggableProduct from '../components/Product/DraggableProduct';
import ProductModal from '../components/Product/ProductModal';
import AddProductModal from '../components/Product/AddProductModal';
import EditProductModal from '../components/Product/EditProductModal';
import './ProductGallery.css';
import { fetchProducts, updateProductPositionOnServer, handleDeleteProduct } from '../services/ProductServices';  // Importamos las funciones


const ProductGallery = ({ store }) => {
  const [products, setProducts] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isAddProductModalOpen, setIsAddProductModalOpen] = useState(false);
  const [isEditProductModalOpen, setIsEditProductModalOpen] = useState(false);
  const [productToEdit, setProductToEdit] = useState(null);
  const [isOwner, setIsOwner] = useState(false);

  const fetchAndSetProducts = useCallback(async () => {
    try {
      const data = await fetchProducts(store);
      const visibleProducts = data.products.filter(producto => (!producto.isHidden)  || data.isOwner);
      setProducts(visibleProducts);
      setIsOwner(data.isOwner);
    } catch (error) {
      console.error('Error al cargar productos:', error);
    }
  }, [store]);

  useEffect(() => {
    fetchAndSetProducts();
  }, [store, fetchAndSetProducts]);

  const openModal = (product) => {
    setSelectedImage(product);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const openAddProductModal = () => {
    setIsAddProductModalOpen(true);
  };

  const closeAddProductModal = () => {
    setIsAddProductModalOpen(false);
  };

  const openEditProductModal = (product) => {
    setProductToEdit(product);
    setIsEditProductModalOpen(true);
  };

  const closeEditProductModal = () => {
    setProductToEdit(null);
    setIsEditProductModalOpen(false);
  };

  const handleDelete = async (uuid) => {
    try {
      await handleDeleteProduct(store, uuid);
      fetchAndSetProducts();
      closeModal();
    } catch (error) {
      console.error('Error al eliminar el producto:', error);
    }
  };

  const updatePosition = async (updatedProducts) => {
    try {
      await updateProductPositionOnServer(store, updatedProducts);
    } catch (error) {
      console.error('Error al actualizar las posiciones:', error);
    }
  };

  return (
    <div className="gallery-background">
      {isOwner && (
        <div className="button-container">
          <button onClick={openAddProductModal} className="add-product-button">
            Agregar Producto
          </button>
        </div>
      )}
      <DraggableProduct
        products={products}
        setProducts={setProducts}
        isOwner={isOwner}
        openEditProductModal={openEditProductModal}
        openModal={openModal}
        updateOrder={updatePosition}
      />
      {selectedImage && (
        <ProductModal
          product={selectedImage}
          onClose={closeModal}
          onEdit={openEditProductModal}
          onDelete={handleDelete}
          esDueno={isOwner}
        />
      )}
      {isAddProductModalOpen && (
        <AddProductModal
          store={store}
          onClose={closeAddProductModal}
          onProductAdded={() => fetchAndSetProducts()}
        />
      )}
      {isEditProductModalOpen && (
        <EditProductModal
          store={store}
          producto={productToEdit}
          onClose={closeEditProductModal}
          onProductEdited={() => fetchAndSetProducts()}
        />
      )}
    </div>
  );
};

export default ProductGallery;
