import authService from './AuthServices'; 

const API_URL = process.env.REACT_APP_API_URL;

// Obtener órdenes del vendedor con paginación
const getSellerOrders = async (route, page = 1, sortOption = 'asc', filterState = '', limit = 10) => {
  try {
    // Obtener el token del almacenamiento local
    let token = localStorage.getItem('accessToken');
    
    // Función auxiliar para hacer la solicitud con el token actual
    const fetchOrders = async (token) => {
      const url = `${API_URL}/orders/${route}?page=${page}&limit=${limit}&orderDate=${sortOption}&filterState=${filterState}`;
      const response = await fetch(url, {
        headers: {
          'Authorization': `Bearer ${token}`,
        },
      });

      // Verificar si el token ha expirado
      if (response.status === 401) {
        return authService.handle401Error(() => getSellerOrders(route, page, sortOption, filterState, limit));
    }

      return await response.json(); // Retornamos la respuesta en formato JSON
    };

    // Intentar obtener las órdenes con el token actual
    let data = await fetchOrders(token);

    // Devolver los datos de las órdenes y el total de páginas
    return { data: data.data, totalPages: data.totalPages };

  } catch (error) {
    // Logs detallados para saber exactamente cuál fue el error
    console.error('Error en OrderService.getSellerOrders:', error.message);

    // Lanzamos el error para manejarlo en el componente
    throw error;
  }
};

// Actualizar el estado de un pedido
const updateOrderStatus = async (orderId, newStatus) => {
  try {
    const token = localStorage.getItem('accessToken');  // Tomamos el token del localStorage
    const response = await fetch(`${API_URL}/orders/${orderId}/status`, {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`,  // Autenticamos la solicitud
      },
      body: JSON.stringify({ status: newStatus }),  // Enviamos el nuevo estado
    });

    if (!response.ok) {
      throw new Error('Error al actualizar el estado del pedido');
    }

    const data = await response.json();
    return data;  // Retornamos la respuesta del servidor
  } catch (error) {
    console.error('Error en OrderService.updateOrderStatus:', error);
    throw error;
  }
};

const OrderService = {
  getSellerOrders,
  updateOrderStatus,
};

export default OrderService;